'use strict';
// Copied from core to wrap password logic in a function & trigger on login:reinit event (for login flyout - account creation screen)
var base = require('base/components/clientSideValidation');

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
base.validateForm = function (event) {
    var valid = true,
        $this = $(this);

    if (this.checkValidity && !this.checkValidity()) {
        valid = false;

        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        if (!this.validity.valid) {
            if ($this.is('input, select')) {
                // handle where 'this' is a form field
                $this.trigger('invalid', this.validity);
            } else {
                $this.find('input, select').each(function () {
                    // handle where 'this' is a form
                    $this.trigger('invalid', this.validity);
                });
            }
        }
    }

    return valid;
}

/**
 * Password Messaging Helper Functions
 */
function initPasswordMessaging() {
    $('form:has(input[type^=password])').each(function (i, form) {
        var $form = $(form),
            $passwordFields = $form.find('input:password'),
            $passwordInput = $form.find('.password-validate'),
            $passwordHelper = $form.find('.password-validation-helper li'), //multiple
            $showPassword = $form.find("#show-password");

        // change the border of the input field to alert the user of acceptance|error
        $passwordInput.on('validatepassword', function (e) {
            if ($passwordHelper.hasClass("error") || $passwordHelper.hasClass("empty")) {
                $passwordInput
                    .removeClass("is-valid")
                    .addClass("is-invalid");
                $passwordHelper
                    .not(".valid")
                    .addClass("error")
                    .removeClass("empty");
            } else {
                $passwordInput
                    .addClass("is-valid")
                    .removeClass("is-invalid");
            }
        });

        $passwordInput.on("keyup", function (e) {
            var $this = $(this);
            var value = $this.val();

            // first check if the form is in error state, then re-eval
            if ($this.hasClass("is-invalid")) {
                // leave the "error" states until valid
                $passwordHelper.trigger('checkrequirement', [value, 'valid empty']);

                $this.trigger('validatepassword');
            } else {
                $passwordHelper.trigger('checkrequirement', [value, 'error valid empty']);
            }
            ;
        });

        // change input decoration when user leaves, attach event only once
        $passwordInput.one("change", function (e) {
            var $this = $(this);

            $this.on("blur", function (e) {
                $this.trigger('validatepassword');
            });
        });

        /**
         * Display validation messages and highlight
         * each one of the requirements for the password
         * @param {string} clearClasses - which classes to clear
         */
        $passwordHelper.on('checkrequirement', function (e, value, clearClasses) {
            var $this = $(this).removeClass(clearClasses).removeClass('valid error empty');
            var regex = new RegExp($this.data('val-regex'));

            if (regex.test(value)) {
                $this.addClass("valid");
            } else if (!regex.test(value) && $passwordInput.focus) {
                $this.addClass("empty error");
            } else {
                $this.addClass("error");
            }
        });

        //show password checkbox
        $showPassword.on('click', event => {
            var $checkbox = $(event.target);

            $passwordFields.each((index, element) => {
                $(element).attr('type', $checkbox.prop('checked') ? 'text' : 'password');
            });
        });
    });
}

initPasswordMessaging();
$('body').on('login:reinit', () => initPasswordMessaging());

function validateFormFix(form, event) {
    base.validateForm.call(form, event || null);
}

base.functions.validateForm = validateFormFix;
module.exports = base;
